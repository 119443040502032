import React from "react"



const SSS = () => (


    <div className="hiring-tabs">
        <input type="checkbox" name="dropdown" id="tab1" />
        <input type="checkbox" name="dropdown" id="tab2" />
        <input type="checkbox" name="dropdown" id="tab3" />
        <input type="checkbox" name="dropdown" id="tab4" />
        <input type="checkbox" name="dropdown" id="tab5" />

        <div className="tab" id="tab1sec">
            <label htmlFor="tab1"> </label>
            <h3>Bir website geliştirmek ne kadar sürüyor?</h3>
            <p>Bir websitesi bileşenlerini bir araya getirmek ve yayına almak için 
gereken süre, birkaç değişkene bağlıdır. Çoğu zaman statik bir website, 
e-ticaret sitelerinden veya daha fazla optimizasyon gerektiren sitelerden 
daha az zaman alır.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab2sec">
            <label htmlFor="tab2"> </label>
            <h3>Hangi platformlar için mobil uygulama geliştiriyorsunuz?</h3>
            <p>Markanız için yaptığımız pazar araştırmaları sonucunda markanızın 
en yüksek verim sağlayacağı platformları belirliyor; Apple, Android vb.
hangi platformlarda olmanız gerekiyorsa buna yönelik uygulamalar 
geliştiriyoruz.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab3sec">
            <label htmlFor="tab3"> </label>
            <h3>Mobil uyumlu website geliştiriyor musunuz?</h3>
            <p>Elbette. Web geliştirme uzmanlarımız, Responsive Web Designing 
olarak da adlandırılan bu website geliştirmelerini yaparak mobilde dahi 
göz alıcı web tasarımları geliştiriyorlar.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab4sec">
            <label htmlFor="tab4"> </label>
            <h3>Website tasarım şablonları sunuyor musunuz?</h3>
            <p>Evet, birkaç farklı website tasarımı içinden sizin için uygun olanı 
geliştiriyoruz. </p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
    
    </div>
)

export default SSS
