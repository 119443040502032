import { Link } from "gatsby"
import React from "react"

import tanoto from "../assets/img/companys/26.png"
import tcdd from "../assets/img/companys/15.png"
import popupsmart from "../assets/img/companys/20.png"
import havelsan from "../assets/img/companys/23.png"
import yatas from "../assets/img/companys/12.png"





const CompanysSEO = () => (
    <div className="company-component">
        <div className="company-box">
            <img src={tanoto} />
        </div>
        <div className="company-box">
            <img src={tcdd} />
        </div>
        <div className="company-box">
            <img src={popupsmart} />
        </div>
        <div className="company-box">
            <img src={havelsan} />
        </div>
        <div className="company-box">
            <img src={yatas} />
        </div>      
        
    </div>
)

export default CompanysSEO
