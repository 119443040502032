import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "../components/gallery/gallery"
import Teams from "../components/teams/web-team"
import SSS from "../components/sss/web-sss"

import tcddDev from "../assets/img/tcdd-dev.png"
import lines from "../assets/img/backgr.png"
import popupsmartDev from "../assets/img/popupsmart-dev-fluid.png"
import circle from "../assets/img/backgr2.png"
import lavarel from "../assets/img/lavarel.png"
import vue from "../assets/img/vue-core.png"
import weAreUsing from "../assets/img/weareusing.png"
import work from "../assets/img/work-preformance.png"
import webdev from "../assets/img/web-development.png"
import checkModal from "../assets/img/check-modal.png";
import Companys from "../components/web-company"


class WebDev extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Mesajınız başarıyla gönderilmiştir.";
            }
            else {
                document.querySelector('.status').innerHTML = "Bir hata oluştu.";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="Web Geliştirme" description="Kullanıcı ihtiyaçlarına yanıt veren, inovatif, dijital trendlere uygun, dünya standartlarında web & mobil yazılım geliştirme hizmeti sunuyoruz." />
                <div className="seo-service container">
                    <div className="seo-service__head">
                        <div className="col-md-6 noPadding" >
                            <h1 style={{ marginTop: 20 }}>Web Geliştirme</h1>
                            <span className="red-head">HİZMETLER</span>
                            <p style={{ marginTop: 25 }}>Kullanıcı ihtiyaçlarına yanıt veren, inovatif, <br />dijital trendlere uygun, dünya standartlarında web & mobil yazılım geliştirme hizmeti sunuyoruz.</p>
                            <input type="text" className="pink-input" placeholder="E-posta adresinizi giriniz." value={this.state.value} onChange={this.handleChange} />
                            <button className="red-button" id="web-dev-button" onClick={(e) => this.showModal()}>Size özel teklif alın</button>
                        </div>
                        <div className="col-md-6" ><img alt="alt text" style={{  }} src={webdev} /> </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Formu doldurun.</h2>                               <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
                            <div className="check-modal_container">
                            <form onSubmit={this.handleSubmit} >
                                    <input type="text" name="text_204202" id="site" required  placeholder="Websitenizi girin." />
                                    <input type="text" id="name" required name="text_775908" placeholder="Adınızı girin." />
                                    <input type="email" id="email" required  name="text_532192" value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="E-posta adresinizi girin." />
                                    <button  >GÖNDER</button>
                                    <div className="status"></div>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="web-dev container" style={{marginTop: -50  }}>
                    <div className="web-dev_brands py60">
                        <h2>Hizmet verdiğimiz tüm markalar.</h2>
                        <Companys />
                    </div>
                    <div className="development-studio py60">
                        <h2>Öne çıkan işlerimizden bazıları.</h2>
                        <div className="tcdd-dev">
                            <img alt="alt text" src={tcddDev}></img>
                            <img alt="alt text" className="lines" src={lines}></img>
                            <h3>TCDD Taşımacılık <br /> Website Geliştirme & UX Tasarım</h3>
                            <p>TCDD yolcularının seyahat planlarını kolayca uygulayabilecekleri, pratik, <br />işlevsel ve kullanıcı odaklı bir website geliştirdik.</p>
                            <img alt="alt text" className="lavarel" src={lavarel}></img>
                        </div>
                        <div className="tcdd-dev" style={{ marginTop: 120 }}>
                            <img alt="alt text" src={popupsmartDev}></img>
                            <img alt="alt text" className="lines2" src={circle}></img>
                            <h3>Popupsmart Website & Mobil Uygulama <br />Geliştirme</h3>
                            <p>Popupsmart müşterilerinin birkaç adımda kolayca popup oluşturabileceği <br />fonksiyonel bir websitesi ve mobil uygulama geliştirdik.</p>
                            <img alt="alt text" className="lavarel" src={vue}></img>
                        </div>
                    </div>




                    <Gallery name="web" />




                    <div className="we-are-using  py60">
                        <h2 className="py60">Kullandığımız teknolojiler.</h2>
                        <img alt="alt text" src={weAreUsing} />
                    </div>

                    <div className="onboarding py60">
                        <div className="row">
                            <div className="col-md-5">
                                <img alt="alt text" src={work} />
                                <h2>Neden
                                    Markalar<br />
                                    Web Geliştirme
                                    İçin<br /> Flatart’ı
                                    Tercih Ediyor?
                            </h2>
                                <p>Website ve mobil uygulama geliştirme ihtiyacınız için <br /> bizi seçtiğiniz durumda deneyimleyebileceğiniz özelliklerimizden bazıları.</p>
                            </div>
                            <div className="col-md-7 flatart-dev-list">
                                <ul>
                                    <li> <span className="tick"></span> Agile (çevik) web tasarım ve geliştirme metodu</li>
                                    <li> <span className="tick"></span> Ödüllü web çözümleri ve websiteleri </li>
                                    <li> <span className="tick"></span> Web tasarımı ve geliştirme konusunda rekabetçi fiyatlar</li>
                                    <li> <span className="tick"></span> Alanında uzman kişilerin oluşturduğu çapraz fonksiyonel ekip </li>
                                    <li> <span className="tick"></span> In-house front-end uzmanları: İş analistleri, UI/UX uzmanları & tasarımcılar</li>
                                    <li> <span className="tick"></span> Müşterilerle açık ve iş birliğine dayalı iletişim yolu</li>
                                    <li> <span className="tick"></span> Başarısı kanıtlanmış web tasarımı ve web geliştirmeleri</li>
                                    <li> <span className="tick"></span> Projeyi hayata geçirmeden önce uygulanan kalite testi </li>
                                    <li> <span className="tick"></span> Maliyeti düşüren web geliştirme süreci</li>
                                    <li> <span className="tick"></span> Benzersiz bir teknoloji deneyimi ve uzmanlığı</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="team">
                    <h2 style={{ textAlign: 'center', marginBottom: 120 }}> Size yardımcı olabilecek <br />Web & Mobil Geliştiricilerimiz. </h2>
                    <Teams />
                </div>
                <div className="hiring" style={{ marginTop: -20 }}>
                    <h2>Sıkça Sorulan Sorular</h2>
                    <SSS />
                </div>
            </Layout>
        )
    }
}
export default WebDev
